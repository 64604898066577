import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { useRouter } from 'src/routes/hooks';
// hooks
// auth
import { useRecoilState } from 'recoil';
import { authStore } from 'src/recoil';
// components
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { removeCookie } from 'src/services/cookies';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();

  const [authenticated, setAuthenticated] = useRecoilState(authStore);

  const popover = usePopover();

  const handleLogout = () => {
    popover.onClose();
    setAuthenticated({ EMAIL: '', TYPE: '', LEVEL: '', NAME: '', TOKEN: '' });
    removeCookie('dm_adm_access_token');
    router.push('/auth/jwt/login');
  };

  const handleClickItem = (path: string) => {
    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <Stack direction={'row'} sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Typography sx={{ lineHeight: '50px', fontWeight: '600', fontSize: '15px' }}> {authenticated?.NAME} 마스터님, 안녕하세요 👋 </Typography>

        <Box>
          <IconButton
            component={m.button}
            whileTap="tap"
            whileHover="hover"
            variants={varHover(1.05)}
            onClick={popover.onOpen}
            sx={{
              width: 40,
              height: 40,
              background: (theme) => alpha(theme.palette.grey[500], 0.08),
              ...(popover.open && {
                background: (theme) => `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.lighter} 100%)`
              })
            }}
          >
            <Avatar
              src={'/static/mock-images/avatars/avatar_default.jpg'}
              alt={authenticated?.NAME}
              sx={{
                fontSize: '14px',
                width: 36,
                height: 36,
                color: '#006868',
                fontWeight: '700',
                backgroundColor: 'white',
                border: (theme) => `solid 2px ${theme.palette.primary.main}`
              }}
            />
          </IconButton>

          <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
            <Box sx={{ p: 2, pb: 1.5 }}>
              <Typography variant="subtitle2" noWrap>
                {authenticated?.NAME} 마스터
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {authenticated?.EMAIL}
              </Typography>
            </Box>

            <Divider sx={{ borderStyle: 'dashed' }} />

            {/* <Stack sx={{ p: 1 }}> */}
            {/*   {OPTIONS.map((option) => ( */}
            {/*     <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}> */}
            {/*       {option.label} */}
            {/*     </MenuItem> */}
            {/*   ))} */}
            {/* </Stack> */}

            <Divider sx={{ borderStyle: 'dashed' }} />

            <MenuItem onClick={handleLogout} sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}>
              로그아웃
            </MenuItem>
          </CustomPopover>
        </Box>
      </Stack>
    </>
  );
}
