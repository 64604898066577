// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// routes
import { RecoilRoot } from 'recoil';
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsProvider } from 'src/components/settings';
import CustomSnackbar from './components/custom-snackbar/custom-snackbar';
// overlay-kit
import { OverlayProvider } from 'overlay-kit';
// tansctack-react-query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false // 창 포커스 시 refetch 비활성화
    }
  }
});

export default function App() {
  useScrollToTop();

  return (
    <RecoilRoot>
      <SettingsProvider
        defaultSettings={{
          themeMode: 'light', // 'light' | 'dark'
          themeDirection: 'ltr', //  'rtl' | 'ltr'
          themeContrast: 'default', // 'default' | 'bold'
          themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
          themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
          themeStretch: true
        }}
      >
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <OverlayProvider>
              <MotionLazy>
                <ProgressBar />
                <CustomSnackbar />
                <Router />
              </MotionLazy>
            </OverlayProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </SettingsProvider>
    </RecoilRoot>
  );
}
